<template>
  <v-container
    id="users"
    fluid
    tag="section"
  css framework>
    <v-dialog
      v-model="addDialog.dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ajouter un Bénéficiaire</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="addDialog.last_name"
                  label="Nom de famille*"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="addDialog.first_name"
                  label="Prénom*"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="addDialog.serviceId"
                  :items="activeServices"
                  label="Service*"
                  :item-text="item => item.name + (item.code ? ' [' + item.code + ']' : '')"
                  item-value="id"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="addDialog.clientId"
                  :items="activeClients"
                  label="Mandant*"
                  item-text="name"
                  item-value="id"
                  required
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="addDialog.referrerIds"
                  :items="activeUsers"
                  multiple
                  required
                  label="Référents*"
                  chips
                  deletable-chips
                  :item-text="item => (item.last_name || '').toLocaleUpperCase() +' '+ item.first_name"
                  :item-value="item => item.id"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indique les champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeAdd"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.sending"
            @click="saveAdd"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog.dialog"
      persistent
      max-width="900px"
    >
      <v-card v-if="editDialog.original_customer">
        <v-card-title>
          <span class="text-h5">{{ editDialog.original_customer.last_name.toUpperCase() }} {{ editDialog.original_customer.first_name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editDialog.last_name"
                  label="Nom de famille*"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editDialog.first_name"
                  label="Prénom*"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="editDialog.serviceId"
                  :items="activeServices"
                  label="Service*"
                  :item-text="item => item.name + (item.code ? ' [' + item.code + ']' : '')"
                  item-value="id"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="editDialog.clientId"
                  :items="activeClients"
                  label="Mandant*"
                  item-text="name"
                  item-value="id"
                  required
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="editDialog.referrerIds"
                  :items="activeUsers"
                  multiple
                  required
                  label="Référents*"
                  chips
                  deletable-chips
                  :item-text="item => (item.last_name || '').toLocaleUpperCase() +' '+ item.first_name"
                  :item-value="item => item.id"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indique les champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeEdit"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="editDialog.sending"
            @click="saveEdit"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="archiveDialog.dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title
          v-if="archiveDialog.customer"
          class="text-h5"
        >
          Voulez-vous vraiment archiver le bénéficiaire <br>
          {{ archiveDialog.customer.last_name }} {{ archiveDialog.customer.first_name }} ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red"
            text
            @click="closeArchive()"
          >
            annuler
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="archive()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="unarchiveDialog.dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title
          v-if="unarchiveDialog.customer"
          class="text-h5"
        >
          Voulez-vous vraiment réactiver le bénéficiaire <br>
          {{ unarchiveDialog.customer.last_name }} {{ unarchiveDialog.customer.first_name }} ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red"
            text
            @click="closeUnarchive()"
          >
            annuler
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="unarchive()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-material-card
      class="px-5 py-3"
    >
      <template
        v-slot:heading
      >
        <div
          class="d-flex flex-wrap align-center"
          style="margin: -15px 0"
        >
          <div class="text-h4 font-weight-light">
            Liste des Bénéficiaires
          </div>
          <v-spacer />
          <v-text-field
            v-model="search"
            class="pt-0 mt-0"
            style="max-width: 400px"
            append-icon="mdi-magnify"
            label="Chercher"
            single-line
            hide-details
            clearable
          />
          <v-btn
            color="green"
            class="ml-5"
            dark
            fab
            title="Ajouter un bénéficiaire"
            @click="openAdd()"
          >
            <v-icon
              color="white"
              dark
            >
              mdi-account-plus
            </v-icon>
          </v-btn>
        </div>
      </template>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              Nom
            </th>
            <th class="primary--text">
              Prénom
            </th>
            <th class="primary--text text-right fit">
              Temps d'activités associées
            </th>
            <th class="primary--text fit">
              Mesure actuelle
            </th>
            <th class="primary--text">
              Mandant
            </th>
            <th class="primary--text text-right fit">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="customer in filteredCustomers"
            :key="customer.id"
            :class="{archived: customer.status === 'archived'}"
          >
            <td class="fit">
              {{ customer.last_name.toUpperCase() }}
            </td>
            <td>{{ customer.first_name }}</td>
            <td class="text-right fit">
              {{ minutesToHoursMinutes(customer.activities.reduce((sum, activity) => activity.minutes + sum, 0)) }}
            </td>
            <td class="fit">
              {{ customer.service.name }}
            </td>
            <td class="fit">
              {{ customer.client.name }}
            </td>
            <td class="text-right fit pl-10">
              <v-btn
                icon
                text
                small
                title="éditer"
                dark
                @click="openEdit(customer)"
              >
                <v-icon
                  color="primary"
                  dark
                >
                  mdi-account-edit
                </v-icon>
              </v-btn>
              <v-btn
                v-if="customer.status === 'published'"
                icon
                text
                small
                dark
                class="ml-2"
                title="archiver"
                @click="openArchive(customer)"
              >
                <v-icon
                  color="red"
                  dark
                >
                  mdi-archive
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                text
                small
                dark
                class="ml-2"
                title="désarchiver"
                @click="openUnarchive(customer)"
              >
                <v-icon
                  color="green"
                  dark
                >
                  mdi-archive
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import { minutesToHoursMinutes } from '../../../utils'
  import store from '../../../store'
  import { mapGetters, mapState } from 'vuex'

  export default {
    name: 'Customers',
    store,
    data () {
      return {
        search: null,
        archiveDialog: {
          dialog: false,
          customer: null,
          uploading: false,
        },
        unarchiveDialog: {
          dialog: false,
          customer: null,
          uploading: false,
        },
        addDialog: {
          sending: false,
          dialog: false,
          first_name: '',
          last_name: '',
          serviceId: null,
          referrerIds: [],
          uploading: false,
        },
        editDialog: {
          sending: false,
          dialog: false,
          id: null,
          original_customer: null,
          first_name: '',
          last_name: '',
          serviceId: null,
          clientId: null,
          referrerIds: [],
          uploading: false,
        },
      }
    },
    computed: {
      filteredCustomers () {
        let customers = this.customers
        if (this.search) {
          const search = this.search.toLowerCase()
          customers = customers.filter(customer => customer.first_name.toLowerCase().includes(search) || customer.last_name.toLowerCase().includes(search))
        }
        return customers
      },
      ...mapState([
        'customers',
      ]),
      ...mapGetters([
        'activeUsers',
        'activeServices',
        'activeClients',
      ]),
    },
    watch: {
      '$route.name': {
        handler () {
          this.$nextTick(() => this.$store.dispatch('fetch_customers').catch(error => this.$handleAuthError(error)))
        },
        deep: true,
        immediate: true,
      },
    },
    created () {
      this.$store.dispatch('fetch_users')
    },
    methods: {
      minutesToHoursMinutes,
      archive () {
        this.archiveDialog.uploading = true
        fetch(
          'https://api.chronophage.xenote.ch/items/customers/' + this.archiveDialog.customer.id,
          {
            method: 'PATCH',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
            body: JSON.stringify({
              status: 'archived',
            }),
          },
        ).then(response => {
          this.archiveDialog.uploading = false
          this.$store.dispatch('fetch_customers').catch(error => this.$handleAuthError(error))
          this.archiveDialog.dialog = false
          return response
        })
      },
      openEdit (customer) {
        this.editDialog.uploading = false
        this.$store.dispatch('fetch_clients')
        this.$store.dispatch('fetch_services')
        this.$store.dispatch('fetch_users')
        this.editDialog.id = customer.id
        this.editDialog.original_customer = customer
        this.editDialog.first_name = customer.first_name
        this.editDialog.last_name = customer.last_name
        this.editDialog.serviceId = customer.service.id
        this.editDialog.clientId = customer.client.id
        this.editDialog.referrerIds = customer.referrers.map(referrer => referrer.directus_users_id.id)
        this.editDialog.dialog = true
      },
      closeEdit () {
        this.editDialog.uploading = false
        this.editDialog.original_customer = null
        this.editDialog.first_name = ''
        this.editDialog.last_name = ''
        this.editDialog.serviceId = null
        this.editDialog.clientId = null
        this.editDialog.referrerIds = []
        this.editDialog.dialog = false
      },
      saveEdit () {
        const object = {}
        if (this.editDialog.last_name !== this.editDialog.original_customer.last_name) {
          object.last_name = this.editDialog.last_name
        }
        if (this.editDialog.first_name !== this.editDialog.original_customer.first_name) {
          object.first_name = this.editDialog.first_name
        }
        if (this.editDialog.clientId !== this.editDialog.original_customer.client.id) {
          object.client = this.editDialog.clientId
        }
        if (this.editDialog.serviceId !== this.editDialog.original_customer.service.id) {
          object.service = this.editDialog.serviceId
        }
        const originalReferrersIds = this.editDialog.original_customer.referrers.map(referrer => referrer.directus_users_id.id)
        if (this.editDialog.referrerIds !== originalReferrersIds) {
          object.referrers = []
          for (const referrerId of this.editDialog.referrerIds) {
            object.referrers.push({
              directus_users_id: referrerId,
            })
          }
        }

        this.editDialog.uploading = true
        return fetch(
          `https://api.chronophage.xenote.ch/items/customers/${this.editDialog.original_customer.id}`,
          {
            method: 'PATCH',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
            body: JSON.stringify(object),
          },
        ).then(response => {
          this.$store.dispatch('fetch_customers').catch(error => this.$handleAuthError(error))
          this.editDialog.uploading = false
          this.closeEdit()
          return response
        })
      },
      openAdd () {
        this.$store.dispatch('fetch_collections').catch(error => this.$handleAuthError(error))
        this.addDialog.uploading = false
        this.addDialog.first_name = ''
        this.addDialog.last_name = ''
        this.addDialog.serviceId = null
        this.addDialog.clientId = null
        this.addDialog.referrerIds = []
        this.addDialog.dialog = true
      },
      closeAdd: function () {
        this.addDialog.uploading = false
        this.addDialog.first_name = ''
        this.addDialog.last_name = ''
        this.addDialog.serviceId = null
        this.addDialog.clientId = null
        this.addDialog.referrerIds = []
        this.addDialog.dialog = false
      },
      saveAdd () {
        const object = {}
        object.last_name = this.addDialog.last_name
        object.first_name = this.addDialog.first_name
        object.client = this.addDialog.clientId
        object.service = this.addDialog.serviceId
        object.referrers = this.addDialog.referrerIds.map(referrerId => { return { directus_users_id: referrerId } })

        this.editDialog.uploading = true
        return fetch(
          'https://api.chronophage.xenote.ch/items/customers',
          {
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
            body: JSON.stringify(object),
          },
        ).then(response => {
          this.$store.dispatch('fetch_customers').catch(error => this.$handleAuthError(error))
          this.addDialog.uploading = false
          this.closeAdd()
          return response
        })
      },
      openArchive (customer) {
        this.archiveDialog.uploading = false
        this.archiveDialog.customer = customer
        this.archiveDialog.dialog = true
      },
      closeArchive () {
        this.archiveDialog.customer = null
        this.archiveDialog.dialog = false
      },
      unarchive () {
        this.unarchiveDialog.uploading = true
        fetch(
          'https://api.chronophage.xenote.ch/items/customers/' + this.unarchiveDialog.customer.id,
          {
            method: 'PATCH',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
            body: JSON.stringify({
              status: 'published',
            }),
          },
        ).then(response => {
          this.unarchiveDialog.uploading = false
          this.$store.dispatch('fetch_customers').catch(error => this.$handleAuthError(error))
          this.unarchiveDialog.dialog = false
          return response
        })
      },
      openUnarchive (customer) {
        this.unarchiveDialog.uploading = false
        this.unarchiveDialog.customer = customer
        this.unarchiveDialog.dialog = true
      },
      closeUnarchive () {
        this.unarchiveDialog.customer = null
        this.unarchiveDialog.dialog = false
      },
    },
  }
</script>

<style scoped>
.fit {
  width: 1%;
  white-space: nowrap;
}

.archived {
  background-color: #d2d2d2;
}
</style>
